import React from "react"
import "./Register.styles.scss"
import { Link } from "react-router-dom";

export function Register() {
    return(
        <div className="register-login-section">
            <div className="col-lg-6 offset-lg-3">
                <div className="register-form">
                    <h2>Register</h2>
                    <form>
                        <div className="group-input">
                            <label>
                                Username or email address *
                            </label>
                            <input type="text"></input>
                        </div>
                        <div className="group-input">
                            <label>
                                Password *
                            </label>
                            <input type="password"></input>
                        </div>
                        <div className="group-input">
                            <label>
                                Confirm Password *
                            </label>
                            <input type="password"></input>
                        </div>
                        <button type="submit" className="site-btn register-btn">REGISTER</button>
                    </form>
                    <div className="switch-login">
                        <Link to="/login" className="or-login">Or Login</Link>
                    </div>
                </div>
            </div>
        </div>
    )

}



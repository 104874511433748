import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SORT_BY_MEN,
  CLEAR_CART,
  SORT_BY_KIDS,
  SORT_BY_WOMEN,
  SORT_BY_BRAND,
  SORT_BY_PRICE,
  SORT_BY_SIZE,
  SORT_BY_TAG,
  SORT_BY_COLOR,
} from "../../redux/shop";
export default function Panel() {
  const [ck, setCk] = useState("");
  const [diesel, setDiesel] = useState("");
  const [tommy, setTommy] = useState("");
  const [minValue, setMinValue] = useState(33);
  const [maxValue, setMaxValue] = useState(98);
  const [selected, setSelected] = useState("");
  const [size, setSize] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedSizeName, setSelectedSizeName] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTagName, setSelectedTagName] = useState("");
  const dispatch = useDispatch();
  const { shop } = useSelector((state) => state.shop);

  const toggle = (i) => {
    if (selectedSize === i) {
      return setSelectedSize(null);
    }
    setSelectedSize(i);
  };

  const toggleTags = (i) => {
    if (selectedTag === i) {
      return setSelectedTag(null);
    }
    setSelectedTag(i);
  };

  let colors = ["black", "blue", "red"];
  let colors2 = ["violet", "yellow", "green"];
  return (
    <div className="shop__panel__container">
      <ul>
        <li className="panel__title">Categories</li>
        <li onClick={() => dispatch(SORT_BY_MEN())}>Men</li>
        <li onClick={() => dispatch(SORT_BY_WOMEN())}>Women</li>
        <li onClick={() => dispatch(SORT_BY_KIDS())}>Kids</li>
      </ul>
      <ul id="category">
        <li className="panel__title">Brands</li>
        {["Calvin Klein", "Diesel", "Tommy Hilfiger"].map((el) => (
          <li onClick={() => dispatch(SORT_BY_BRAND(el))}>{el}</li>
        ))}

        <li className="panel__title">
          Price
          <div className="panel__price__container">
            {" "}
            <div className="slider__value">${minValue}</div>
            <span className="panel__dash"> -</span>{" "}
            <div className="slider__value">${maxValue}</div>
          </div>
          <section class="range-slider">
            <input
              defaultValue={0}
              min="33"
              max="98"
              step="1"
              type="range"
              onChange={(e) => setMinValue(e.target.value)}
              style={{ height: "1px" }}
            />
            <input
              defaultValue={100}
              min="33"
              max="98"
              step="1"
              type="range"
              onChange={(e) => setMaxValue(e.target.value)}
              style={{ height: "1px" }}
            />
          </section>
        </li>
        <button
          className="panel__filter__btn"
          onClick={() => dispatch(SORT_BY_PRICE({ minValue, maxValue }))}
        >
          Filter
        </button>
      </ul>
      <div className="panel__colors">
        <div className="colors__row">
          {colors.map((el, i) => (
            <div className="panel__radio__choice" key={i}>
              {" "}
              <input
                className="radio__panel"
                type="radio"
                id="radio"
                name="fav_language"
                value={el}
                onChange={(e) => setSelected(e.target.value)}
                style={{ boxShadow: `0 0 10px 10px ${el} inset` }}
                onClick={() => dispatch(SORT_BY_COLOR(el))}
              />
              <label for="radio">{el}</label> <br />
            </div>
          ))}
        </div>
        <div className="colors__row">
          {colors2.map((el, i) => (
            <div className="panel__radio__choice">
              {" "}
              <input
                className="radio__panel"
                type="radio"
                id="radio"
                name="fav_language"
                value={el}
                onChange={(e) => setSelected(e.target.value)}
                style={{ boxShadow: `0 0 10px 10px ${el} inset` }}
                onClick={() => dispatch(SORT_BY_COLOR(el))}
              />
              <label for="radio">{el}</label> <br />
            </div>
          ))}
        </div>
      </div>
      <li className="panel__title">Size</li>
      <div className="panel__sizes">
        {["S", "M", "L", "XS"].map((el, i) => (
          <div
            className={selectedSize === i ? "invert" : "panel__size"}
            key={i}
            onClick={() => {
              setSelectedSizeName(el);
              toggle(i);
              toggleTags("");
              dispatch(SORT_BY_SIZE(el));
            }}
          >
            {el}
          </div>
        ))}
      </div>
      <li className="panel__title">Tags</li>
      <div className="tags__container">
        {["Towel", "Shoes", "Coat", "Dresses", "Trousers", "Backpack"].map(
          (el, i) => (
            <div
              className={
                selectedTag === i ? "shop__tags__clicked" : "shop__tags"
              }
              key={i}
              onClick={() => {
                setSelectedTagName(el);
                toggleTags(i);
                setSelectedSize("");
                dispatch(SORT_BY_TAG(el.toLowerCase()));
              }}
            >
              {el}
            </div>
          )
        )}
      </div>
    </div>
  );
}

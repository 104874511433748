import image1 from "../assets/images/instagram/1.webp";
import image2 from "../assets/images/instagram/2.webp";
import image3 from "../assets/images/instagram/3.webp";
import image4 from "../assets/images/instagram/4.webp";
import image5 from "../assets/images/instagram/5.webp";
import image6 from "../assets/images/instagram/6.webp";

import blog1 from "../assets/images/blog1.webp";
import blog2 from "../assets/images/blog2.webp";
import blog3 from "../assets/images/blog3.webp";

import sweter1 from "../assets/images/sweter-1.webp";
import sweter2 from "../assets/images/sweter-2.webp";
import sweter3 from "../assets/images/sweter-3.webp";
import sweter4 from "../assets/images/sweter-4.webp";
import sweter5 from "../assets/images/sweter-5.webp";

import accessories1 from "../assets/images/accesories-1.webp";
import accessories2 from "../assets/images/accesories-2.webp";
import accessories3 from "../assets/images/accesories-3.webp";
import accessories4 from "../assets/images/accesories-4.webp";

import shoe1 from "../assets/images/shoe-1.webp";
import shoe2 from "../assets/images/shoe-2.webp";
import shoe3 from "../assets/images/shoe-3.webp";
import shoe4 from "../assets/images/shoe-4.webp";
import shoe5 from "../assets/images/shoe-5.webp";

import handbag1 from "../assets/images/handbag-1.webp";
import handbag2 from "../assets/images/handbag-2.webp";
import handbag3 from "../assets/images/handbag-3.webp";
import handbag4 from "../assets/images/handbag-4.webp";
import handbag5 from "../assets/images/handbag-5.webp";

import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const initialState = {
  shop: [
    {
      id: 0,
      name: "Black Sweter",
      type: "towel",
      price: 23.4,
      oldPrice: 67.7,
      category: "sweter",
      image: sweter1,
      gender: "men",
      brand: "Calvin Klein",
      size: "S",
      tag: "towel",
      color: "red",
    },
    {
      id: 1,
      name: "Warm Check Sweter",
      type: "cardigans",
      price: 13.9,
      sale: true,
      category: "sweter",
      image: sweter2,
      gender: "men",
      brand: "Calvin Klein",
      size: "S",
      tag: "towel",
      color: "red",
    },
    {
      id: 2,
      name: "Tunics Ladies Sweter",
      type: "tunics",
      price: 43.4,
      oldPrice: 67.7,
      category: "sweter",
      image: sweter3,
      gender: "men",
      brand: "Calvin Klein",
      size: "S",
      tag: "towel",
      color: "red",
    },
    {
      id: 3,
      name: "Turtlenecks Sweter",
      type: "turtlenecks",
      price: 39.5,
      category: "sweter",
      image: sweter4,
      gender: "women",
      brand: "Diesel",
      size: "S",
      tag: "shoes",
      color: "red",
    },
    {
      id: 4,
      name: "Orange Huddy",
      type: "pullovers",
      price: 20.2,
      category: "sweter",
      image: sweter5,
      sale: true,
      gender: "women",
      brand: "Diesel",
      size: "S",
      tag: "shoes",
      color: "black",
    },
    {
      id: 5,
      name: "Diomond Braclet",
      type: "braclet",
      price: 243,
      category: "accesories",
      image: accessories1,
      sale: true,
      gender: "kids",
      brand: "Diesel",
      size: "M",
      tag: "shoes",
      color: "black",
    },
    {
      id: 6,
      name: "Golden Eyelaner",
      type: "EyeLen",
      oldPrice: 189.4,
      price: 113.9,
      category: "accesories",
      image: accessories2,
      gender: "kids",
      brand: "Tommy Hilfiger",
      size: "M",
      tag: "shoes",
      color: "black",
    },
    {
      id: 7,
      name: "Smart Watch",
      type: "watch",
      price: 67.4,
      category: "accesories",
      image: accessories3,
      gender: "kids",
      brand: "Tommy Hilfiger",
      size: "M",
      tag: "shoes",
      color: "violet",
    },
    {
      id: 8,
      name: "Golden Classic Watch",
      type: "watch",
      price: 339.5,
      category: "accesories",
      image: accessories4,
      sale: true,
      gender: "kids",
      brand: "Tommy Hilfiger",
      size: "M",
      tag: "coat",
      color: "violet",
    },
    {
      id: 9,
      name: "Designed Sport Shoe",
      type: "sport",
      oldPrice: 189.4,
      price: 89.3,
      category: "shoe",
      image: shoe1,
      gender: "kids",
      brand: "Tommy Hilfiger",
      size: "M",
      tag: "coat",
      color: "violet",
    },
    {
      id: 10,
      name: "Walking Shoe",
      type: "walking",
      price: 64.3,
      category: "shoe",
      sale: true,
      image: shoe2,
      gender: "women",
      brand: "Tommy Hilfiger",
      size: "M",
      tag: "coat",
      color: "violet",
    },
    {
      id: 11,
      name: "Comfortable Shoe",
      type: "sport",
      price: 99.3,
      category: "shoe",
      image: shoe3,
      gender: "kids",
      brand: "Tommy Hilfiger",
      size: "M",
      tag: "coat",
      color: "green",
    },
    {
      id: 12,
      name: "Pullovers Shoe",
      type: "pullovers",
      price: 20.2,
      oldPrice: 189.4,
      category: "shoe",
      sale: true,
      image: shoe4,
      gender: "women",
      brand: "Tommy Hilfiger",
      size: "L",
      tag: "coat",
      color: "green",
    },
    {
      id: 13,
      name: "Gray Shoe",
      type: "pullovers",
      price: 45.2,
      category: "shoe",
      image: shoe5,
      gender: "women",
      brand: "Tommy Hilfiger",
      size: "L",
      tag: "dresses",
      color: "red",
    },
    {
      id: 14,
      name: "Leather Handbag",
      type: "cordigans",
      price: 145.2,
      category: "handbag",
      image: handbag1,
      gender: "women",
      brand: "Tommy Hilfiger",
      size: "L",
      tag: "dresses",
      color: "yellow",
    },
    {
      id: 15,
      name: "Whiteshape Handbag",
      type: "whiteshape",
      price: 236.2,
      category: "handbag",
      image: handbag2,
      gender: "women",
      brand: "Tommy Hilfiger",
      size: "L",
      tag: "trousers",
      color: "yellow",
    },
    {
      id: 16,
      name: "Touring Handbag",
      type: "tour",
      price: 315.9,
      oldPrice: 189.4,
      category: "handbag",
      sale: true,
      image: handbag3,
      gender: "women",
      brand: "Tommy Hilfiger",
      size: "XS",
      tag: "backpack",
      color: "blue",
    },
    {
      id: 17,
      name: "Stylish Handbag",
      type: "stylish",
      price: 545.2,
      category: "handbag",
      image: handbag4,
      sale: true,
      gender: "women",
      brand: "Tommy Hilfiger",
      size: "XS",
      tag: "backpack",
      color: "blue",
    },
    {
      id: 18,
      name: "Leather mini Handbag",
      type: "leather",
      price: 295.2,
      category: "handbag",
      image: handbag5,
      oldPrice: 189.4,
      gender: "men",
      brand: "Tommy Hilfiger",
      size: "XS",
      tag: "backpack",
      color: "blue",
    },
  ],
  filtered: [],
};
initialState.filtered = [...initialState.shop];
export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    CLEAR_CART: (state) => {
      state.filtered = [];
    },
    SORT_BY_MEN: (state) => {
      state.filtered = state.shop.filter((el) => el.gender === "men");
    },
    SORT_BY_WOMEN: (state) => {
      state.filtered = state.shop.filter((el) => el.gender === "women");
    },
    SORT_BY_KIDS: (state) => {
      state.filtered = state.shop.filter((el) => el.gender === "kids");
    },
    SORT_BY_BRAND: (state, action) => {
      state.filtered = state.shop.filter((el) => el.brand == action.payload);
    },
    SORT_BY_PRICE: (state, action) => {
      state.filtered = state.shop.filter(
        (el) =>
          el.price >= action.payload.minValue &&
          el.price <= action.payload.maxValue
      );
    },
    SORT_BY_SIZE: (state, action) => {
      state.filtered = state.shop.filter((el) => el.size === action.payload);
    },
    SORT_BY_TAG: (state, action) => {
      state.filtered = state.shop.filter((el) => el.tag === action.payload);
    },
    SORT_BY_COLOR: (state, action) => {
      state.filtered = state.shop.filter((el) => el.color === action.payload);
    },
  },
});
export const {
  SORT_BY_MEN,
  CLEAR_CART,
  SORT_BY_KIDS,
  SORT_BY_WOMEN,
  SORT_BY_BRAND,
  SORT_BY_PRICE,
  SORT_BY_SIZE,
  SORT_BY_TAG,
  SORT_BY_COLOR,
} = shopSlice.actions;
export default shopSlice.reducer;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faShuffle,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { ADD_ITEM, REMOVE_ITEM_BY_INDEX, CLEAR_CART } from "../../redux/cart";
import { ADD_WISHLIST_ITEM, REMOVE_WISHLIST_ITEM } from "../../redux/wishlist";
import "./ProductItem.styles.scss";

function ProductItem({ item }) {
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const handleAddWishlist = (item) => {
    const exists = wishlist.find((listItem) => listItem === item);
    if (!exists) {
      dispatch(ADD_WISHLIST_ITEM(item));
      return;
    }
    dispatch(REMOVE_WISHLIST_ITEM(wishlist.indexOf(item)));
  };

  return (
    <div className="Product_Container">
      <div className="Product_Image">
        <img src={item.image} alt="product item" />
        <div className="Product_Hover_Buttons">
          <div
            className="Product_Hover_Button_Icon"
            onClick={() => dispatch(ADD_ITEM(item))}
          >
            <FontAwesomeIcon icon={faCartShopping} />
          </div>
          <p className="Product_Hover_Button_Text">+ quick view</p>
          <div className="Product_Hover_Button_Icon_Second">
            <FontAwesomeIcon icon={faShuffle} />
          </div>
        </div>
        <div
          onClick={() => handleAddWishlist(item)}
          className="Product_Hover_Heart"
        >
          <FontAwesomeIcon icon={faHeart} />
        </div>
        {item?.sale && <p className="Product_Sale_Icon">Sale</p>}
      </div>
      <div className="Product_Conent">
        <p className="Product_Title">{item.type}</p>
        <p className="Product_Name">{item.name}</p>
        <p className="Product_Price">
          $ {item.price}{" "}
          {item?.oldPrice && (
            <span className="Product_OldPrice">$ {item?.oldPrice}</span>
          )}
        </p>
      </div>
    </div>
  );
}

export default ProductItem;

import React from "react";
import "./CartPage.styles.scss"
import Cart from "../../components/Cart/Cart"
import CartPrice from "../../components/CartPrice/CartPrice";

export default function CartPage() {
    return(
        <section className="shopping_cart">
            <div className="container">
                 <Cart />
                 <CartPrice />
            </div>
        </section>
    )
}
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import CarouselSlider from "../../components/CarouselSlider/CarouselSlider";
import carouselBanner from "../../assets/images/carousel-bannerMen.webp";
import { productData } from "../../assets/data";
import "./HomepageCarouselMen.scss";

const HomepageCarouselMen = () => {
  const tabs = ["sweter", "accesories", "shoe", "handbag"];
  const [selected, setSelected] = useState(tabs[0]);
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    AOS.init({ once: true });
    AOS.refresh();
  }, []);

  useEffect(() => {
    const filteredData = productData.filter(
      (item) => item.category === selected
    );
    setSelectedData(filteredData);
  }, [selected]);

  return (
    <div className="Homepage-Carousel-Section">
      <div
        data-aos="fade-right"
        data-aos-duration="2000"
        className="Homepage-Carousel-Container"
      >
        <div className="Homepage-Carousel-Tab">
          <ul className="Homepage-Carousel-Tab-Container">
            {tabs.map((tab, index) => (
              <li
                key={index}
                onClick={() => setSelected(tab)}
                className={`Homepage-Carousel-Tab-Button ${
                  selected === tab && "Tab-Active"
                }`}
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="Homepage-Carousel-Slider-Container">
          <CarouselSlider data={selectedData} />
        </div>
      </div>
      <div
        data-aos="fade-left"
        data-aos-duration="2000"
        className="Homepage-Carousel-Banner"
        style={{
          background: `url(${carouselBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-reapet",
        }}
      >
        <div className="Homepage-Carousel-Content">
          <h2>Men's</h2>
          <p>Discover more</p>
        </div>
        <div className="Homepage-Banner-Carousel-Banner-Overlay"></div>
      </div>
    </div>
  );
};

export default HomepageCarouselMen;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./RecentPost.styles.scss";

export default function RecentPost({ post }) {
  const titleArray = post.title.split(" ");
  let title = post.title;
  if (titleArray.length > 4) {
    title = "";
    titleArray.slice(0, 4).forEach((word) => (title += word + " "));
    title += "...";
  }

  return (
    <a className="RecentPost_Container" href="#">
      <img className="RecentPost_Image" src={post.image} alt="Recent Post" />
      <div className="RecentPost_Content">
        <p className="RecentPost_Title">{title}</p>
        <div className="RecentPost_Footer">
          <span className="RecentPost_Footer_Brand">Fashion </span>
          <span className="RecentPost_Footer_Date">-{post.date}</span>
        </div>
      </div>
    </a>
  );
}

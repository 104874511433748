import React, { useState } from "react";

import ProductItem from "../../components/ProductItem/ProductItem";
import { productData } from "../../assets/data";
import "./ShopItems.styles.scss";

import { useSelector, useDispatch } from "react-redux";
import {
  SORT_BY_MEN,
  CLEAR_CART,
  SORT_BY_KIDS,
  SORT_BY_WOMEN,
} from "../../redux/shop";
export function ShopItems() {
  const dispatch = useDispatch();

  const { shop } = useSelector((state) => state.shop);
  const { filtered } = useSelector((state) => state.shop);

  const [sorting, setSorting] = useState("0");

  const sortItems = (a, b) => {
    if (sorting === "0") {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
    }
    if (sorting === "1") {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
    } else if (sorting === "2") {
      if (a.price < b.price) {
        return -1;
      }
      if (a.price > b.price) {
        return 1;
      }
    }
    return 0;
  };

  return (
    <div className="Shop_Items_Container">
      <div className="Shop_Items_Options">
        <div className="Shop_Options_Sorting">
          <select
            className="Shop_Select"
            value={sorting}
            onChange={(e) => {
              setSorting(e.target.value);
            }}
          >
            <option value="0">Default Sorting</option>
            <option value="1">Name Sorting</option>
            <option value="2">Price Sorting</option>
          </select>
          <select className="Shop_Select">
            <option>Show : 09</option>
            <option>Show : 10</option>
            <option>Show : 12</option>
          </select>
        </div>
        <div className="Shop_Options_Text">Show 01 - 09 Of 36 Product</div>
      </div>
      <div className="Shop_Items">
        {filtered?.map((item, index) => (
          <div className="Shop_Item" key={index}>
            <ProductItem item={item} />
          </div>
        ))}
      </div>
    </div>
  );
}

import img1 from "../../assets/images/header1.webp";
import img2 from "../../assets/images/header2.webp";

export const data = [
  {
    category: "BAG,KIDS",
    image: img2,
    title: "Black friday",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    dec2: " seddo eiusmod tempor incididunt ut labore et dolore",
  },
  {
    category: "BAG,KIDS",
    image: img1,
    title: "Black friday",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    dec2: " seddo eiusmod tempor incididunt ut labore et dolore",
  },
  {
    category: "BAG,KIDS",
    image: img2,
    title: "Black friday",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    dec2: " seddo eiusmod tempor incididunt ut labore et dolore",
  },
  {
    category: "BAG,KIDS",
    image: img1,
    title: "Black friday",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    dec2: " seddo eiusmod tempor incididunt ut labore et dolore",
  },
];

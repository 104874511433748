import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";

import "./FooterContent.styles.scss";
import FacebookIcon from "../../assets/svgs/facebook.svg";
import InstagramIcon from "../../assets/svgs/instagram.svg";
import TwitterIcon from "../../assets/svgs/twitter.svg";
import PinterestIcon from "../../assets/svgs/pinterest.svg";
import cards from "../../assets/images/cards.webp";
import whiteLogo from "../../assets/images/footer-logo.webp";

const Column1 = () => {
  return (
    <div className="Footer_Column">
      <h1>Information</h1>
      <HashLink to="/#" style={{ textDecoration: "none", color: "#b2b2b2" }}>
        About Us
      </HashLink>
      <HashLink
        to="/#"
        scroll={(el) =>
          el.scrollIntoView({ behavior: "auto", block: "center" })
        }
        style={{ textDecoration: "none", color: "#b2b2b2" }}
      >
        Checkout
      </HashLink>
      <HashLink
        to="/#"
        scroll={(el) =>
          el.scrollIntoView({
            behavior: "auto",
            block: "center",
          })
        }
        style={{ textDecoration: "none", color: "#b2b2b2" }}
      >
        Contact
      </HashLink>
      <HashLink to="/#" style={{ textDecoration: "none", color: "#b2b2b2" }}>
        Serivius
      </HashLink>
    </div>
  );
};

const Column2 = () => {
  return (
    <div className="Footer_Column">
      <h1>My Account</h1>
      <HashLink to="/#" style={{ textDecoration: "none", color: "#b2b2b2" }}>
        My Account
      </HashLink>
      <HashLink
        to="/#"
        scroll={(el) =>
          el.scrollIntoView({ behavior: "auto", block: "center" })
        }
        style={{ textDecoration: "none", color: "#b2b2b2" }}
      >
        Contact
      </HashLink>
      <HashLink
        to="/#"
        scroll={(el) =>
          el.scrollIntoView({
            behavior: "auto",
            block: "center",
          })
        }
        style={{ textDecoration: "none", color: "#b2b2b2" }}
      >
        Shopping Cart
      </HashLink>
      <HashLink to="/#" style={{ textDecoration: "none", color: "#b2b2b2" }}>
        Shop
      </HashLink>
    </div>
  );
};

const Logos = () => {
  return (
    <div className="Logos_Container">
      <div className="title">
        <HashLink to="/#header">
          <img alt="reboost logo" src={whiteLogo} className="Footer_logo" />
        </HashLink>
      </div>
      <div className="Footer_Column">
        <ul>
          <li>Address: London, GB</li>
          <li>Phone: +961 71 523 153</li>
          <li>Email: info@reboost.com</li>
        </ul>
      </div>

      <div className="Logos">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img src={FacebookIcon} />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img src={InstagramIcon} />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img className="Footer_logo" src={TwitterIcon} />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <img className="Footer_logo" src={PinterestIcon} />
        </a>
      </div>
    </div>
  );
};
const Links = ["About", "Portfolio", "Contact", "Templates"];

const InfoSection = () => {
  return (
    <div className="Info_Container" id="footer">
      <Logos />
      <Column1 title="Info" links={Links} />
      <Column2 title="My Account" />
      <div className="ContactInfo">
        <h1>Join Our Newsletter Now</h1>
        <p>Get E-mail updates about our latest shop and special offers.</p>
        <form action="#" className="newsletter_form">
          <input type="email" placeholder="Enter Your Mail" />
          <button type="button"> Subscribe</button>
        </form>
      </div>
    </div>
  );
};
const FooterContent = () => {
  let year = new Date().getFullYear();
  return (
    <div className="Footer_Container" id="footer">
      <InfoSection />
      <p className="Footer_Copyright">
        Copyright ©{year} All rights reserved | Powered By
        <HashLink to="/#" style={{ textDecoration: "none" }}>
          {" "}
          Reboost
        </HashLink>
        <img src={cards} />
      </p>
    </div>
  );
};

export default FooterContent;

import React from "react";
import Blog from "../../components/Blog/Blog";
import { data } from "./data";

export default function BlogSection() {
  return (
    <>
      <div className="blog__header">
        <span>From The Blog</span>
      </div>
      <div className="blog__section">
        {data.map((blog) => (
          <Blog
            title={blog.title}
            description={blog.description}
            image={blog.image}
            date={blog.date}
            comments={blog.comments}
          />
        ))}
      </div>
    </>
  );
}

export default function Category({ img, text }) {
    return (
      <div className="single-banner">
        <img
          src={img}
          className="category__image"
          alt=""
        />
        <div className="inner-text">
          <h4>{text}</h4>
        </div>
      </div>
    );
  }
import React from "react";
import "./InstagramPosts.styles.scss";
import InstagramPost from "../../components/InstagramPost/InstagramPost";
import { instaPosts } from "../../assets/data";

function InstagramPosts() {
  return (
    <div className="Posts_Container">
      {instaPosts &&
        instaPosts.map((post, i) => (
          <InstagramPost
            key={i}
            img={post.image}
            url={post.url}
            text={post.text}
          />
        ))}
    </div>
  );
}

export default InstagramPosts;

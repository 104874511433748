import { faXmark } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REMOVE_ITEM_BY_INDEX } from "../../redux/cart";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import "./NavbarHoverCart.styles.scss";

const NavbarHoverCart = () => {
  const { cart } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const cartPrices = cart ? cart.map((item) => item.price * item.quantity) : 0;

  return (
    <div className="Navbar-Hover-Cart">
      <ul className="Navbar-Hover-Cart-Container">
        {cart?.map((item) => (
          <li className="Navbar-Hover-Cart-Item">
            <div className="Navbar-Hover-Cart-Item-Left">
              <img
                src={item?.image}
                className="Navbar-Hover-Cart-Item-Image"
                alt=""
              />
              <p className="Navbar-Hover-Cart-Item-Text">
                <span className="Navbar-Hover-Cart-Item-Price">
                  ${item?.price} X {item.quantity}
                </span>
                <span className="Navbar-Hover-Cart-Item-Name">
                  {item?.name}
                </span>
              </p>
            </div>
            <div
              onClick={() => dispatch(REMOVE_ITEM_BY_INDEX(cart.indexOf(item)))}
              className="Navbar-Hover-Cart-Item-Right"
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </li>
        ))}
      </ul>
      <div className="Navbar-Hover-Cart-Bottom">
        <p className="Navbar-Hover-Cart-Total">
          <span>total:</span>
          <span>
            $
            {cartPrices.length > 0
              ? cartPrices.reduce((prev, next) => prev + next).toFixed(2)
              : 0}
          </span>
        </p>
        <Link to="cart" className="Navbar-Hover-Cart-Button View-Button">
          view cart
        </Link>
        <Link
          to="checkout"
          className="Navbar-Hover-Cart-Button Checkout-Button"
        >
          chekout
        </Link>
      </div>
    </div>
  );
};

export default NavbarHoverCart;

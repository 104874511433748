import React from "react";
import "./BlogDetailPage.styles.scss"

import BlogDetail from "../../sections/BlogDetail/BlogDetail";
import CommentSection from "../../sections/CommentSection/CommentSection";
import CommentForm from "../../sections/CommentForm/CommentForm"


export default function BlogDetailPage() {
    return(
        <div className="blog_detail">
            <div className="blog_detail_margin">
                <BlogDetail />
                <CommentSection />
                <CommentForm />
            </div>
        </div>
        
    )
}

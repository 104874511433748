import { faSearch, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { GET_TOTAL_QUANTITY } from "../../redux/cart";
import logo from "../../assets/images/logo.webp";
import heartIcon from "../../assets/svgs/heart-icon.svg";
import shoppingBagIcon from "../../assets/svgs/shopping-bag.svg";
import "./NavbarMiddle.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NavbarHoverCart from "../NavbarHoverCart/NavbarHoverCart";
import NavbarHoverWishlist from "../NavbarHoverWishlist/NavbarHoverWishlist";

const NavbarMiddle = () => {
  const { cart } = useSelector((state) => state.cart);
  const { totalquantity } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const cartPrices = cart ? cart.map((item) => item.price * item.quantity) : 0;

  const [getTotal, setTotal] = useState(0);

  useEffect(() => {
    dispatch(GET_TOTAL_QUANTITY());
    setTotal(totalquantity);
  }, [cart, totalquantity]);

  return (
    <div className="Navbar-Middle-Container">
      <Link to={"/"}>
        <img src={logo} className="Navbar-Logo" alt="" />
      </Link>
      <div className="Navbar-Middle-Searchbar">
        <div className="Searchbar-Dropdown">
          <p className="Searchbar-Dropdown-Button">
            <span>All Categories </span>
            <span>
              <FontAwesomeIcon icon={faAngleDown} />{" "}
            </span>
          </p>
        </div>
        <div className="Searchbar-Divider"></div>
        <form className="Searchbar-Form">
          <input
            type="text"
            name="searchValue"
            placeholder="What do you need?"
            className="Searchbar-Form-Field"
          />
          <button
            type="submit"
            name="searchValue"
            value="Submit"
            className="Searchbar-Form-Submit"
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
      </div>
      <div className="Navbar-Button-Container">
        <p className="Navbar-Button-Icon Navbar-Wishlist">
          <img className="Navbar-Button-Icon-Icon" src={heartIcon} alt="" />
          <NavbarHoverWishlist />
          <span className="Navbar-Button-Icon-Bedge">{wishlist?.length}</span>
        </p>
        <p className="Navbar-Button-Icon Navbar-Cartitems">
          <img
            className="Navbar-Button-Icon-Icon"
            src={shoppingBagIcon}
            alt=""
          />
          <span className="Navbar-Button-Icon-Bedge">{getTotal}</span>

          <NavbarHoverCart />
        </p>
        <p className="Navbar-Cart-Amount">
          ${" "}
          {cartPrices.length > 0
            ? cartPrices.reduce((prev, next) => prev + next).toFixed(2)
            : 0}
        </p>
      </div>
    </div>
  );
};

export default NavbarMiddle;

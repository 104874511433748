import React from "react";
import "./styles.scss";
import calendar from "./calendar.svg";
import comment from "./comment.svg";
export default function Blog({date,image,comments,title,description}) {
  return (
    <div className="blog__container">
      <div className="img_container">
        <img
          src={image}
          alt=""
        />
      </div>
      <div className="info">
        <div className="date">
          <img src={calendar} alt="" className="calendar" />
          <span>{date}</span>
        </div>
        <div className="comment">
          <img src={comment} alt="" />
          <span>{comments}</span>
        </div>
      </div>
      <div className="title">
        <span>{title}</span>
      </div>
      <div className="description">
        <p>
          {description}
        </p>
      </div>
    </div>
  );
}
import React, { useState } from "react";
import { data } from "../../sections/CartData/data";
import { useSelector, useDispatch } from "react-redux";
import {
  ADD_ITEM,
  REMOVE_ITEM_BY_INDEX,
  CLEAR_CART,
  ADD_QUANTITY_BY_1,
  DEDUCE_QUANTITY_BY_1,
} from "../../redux/cart";
export default function Cart() {
  const { cart } = useSelector((state) => state.cart);
  const [quantity, setQuantity] = useState("1");
  const dispatch = useDispatch();


  return (
    <div className="cart_table">
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th className="product_name">Product Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Total</th>
            <th>
              <i className="ti_close">&#x2716;</i>
            </th>
          </tr>
        </thead>
        {cart.map((item) => (
          <tbody>
            <tr>
              <td className="cart_pic first_row sweter_img">
                <img className="sweter_img" src={item.image}></img>
              </td>
              <td className="cart_title first_row">
                <h5>{item.name}</h5>
              </td>
              <td className="p_price first_row">${item.price}</td>
              <td className="qua_col first_row">
                <div className="quantity">
                  <div className="pro_qty">
                    <span
                      className="dec qtybtn"
                      onClick={() =>
                        dispatch(DEDUCE_QUANTITY_BY_1(cart.indexOf(item)))
                      }
                    >
                      -
                    </span>
                    <input type="text" value={item.quantity} />
                    <span
                      className="inc qtybtn"
                      onClick={() =>
                        dispatch(ADD_QUANTITY_BY_1(cart.indexOf(item)))
                      }
                    >
                      +
                    </span>
                  </div>
                </div>
              </td>
              <td className="total_price first_row">
                ${item.price * item.quantity}
              </td>
              <td className="close_td fist_row">
                <i
                  className="ti_close"
                  onClick={() =>
                    dispatch(REMOVE_ITEM_BY_INDEX(cart.indexOf(item)))
                  }
                >
                  &#x2716;
                </i>
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}

import React from "react";

import RecentPost from "../../components/RecentPost/RecentPost";
import { recentPosts } from "../../assets/data";
import "./RecentPosts.styles.scss";

export default function RecentPosts() {
  const sortPosts = (a, b) => {
    return new Date(b.date) - new Date(a.date);
  };
  return (
    <div className="RecentPosts_Section">
      <div className="RecentPosts_Title">Recent Post</div>
      <div className="RecentPosts_Container">
        {recentPosts &&
          recentPosts
            .sort(sortPosts)
            .slice(0, 4)
            .map((post, index) => <RecentPost key={index} post={post} />)}
      </div>
    </div>
  );
}

import image1 from "./images/instagram/1.webp";
import image2 from "./images/instagram/2.webp";
import image3 from "./images/instagram/3.webp";
import image4 from "./images/instagram/4.webp";
import image5 from "./images/instagram/5.webp";
import image6 from "./images/instagram/6.webp";

import blog1 from "./images/blog1.webp";
import blog2 from "./images/blog2.webp";
import blog3 from "./images/blog3.webp";

import sweter1 from "./images/sweter-1.webp";
import sweter2 from "./images/sweter-2.webp";
import sweter3 from "./images/sweter-3.webp";
import sweter4 from "./images/sweter-4.webp";
import sweter5 from "./images/sweter-5.webp";

import accessories1 from "./images/accesories-1.webp";
import accessories2 from "./images/accesories-2.webp";
import accessories3 from "./images/accesories-3.webp";
import accessories4 from "./images/accesories-4.webp";

import shoe1 from "./images/shoe-1.webp";
import shoe2 from "./images/shoe-2.webp";
import shoe3 from "./images/shoe-3.webp";
import shoe4 from "./images/shoe-4.webp";
import shoe5 from "./images/shoe-5.webp";

import handbag1 from "./images/handbag-1.webp";
import handbag2 from "./images/handbag-2.webp";
import handbag3 from "./images/handbag-3.webp";
import handbag4 from "./images/handbag-4.webp";
import handbag5 from "./images/handbag-5.webp";

export const instaPosts = [
  { image: image1, url: "#", text: "lorem ipsum" },
  { image: image2, url: "#", text: "lorem ipsum" },
  { image: image3, url: "#", text: "lorem ipsum" },
  { image: image4, url: "#", text: "lorem ipsum" },
  { image: image5, url: "#", text: "lorem ipsum" },
  { image: image6, url: "#", text: "lorem ipsum" },
];

export const recentPosts = [
  {
    date: "January 4,2020",
    comments: 5,
    image: blog1,
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    description:
      "Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat",
  },
  {
    date: "May 5,2019",
    comments: 5,
    image: blog2,
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    description:
      "Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat",
  },
  {
    date: "September 6,2019",
    comments: 5,
    image: blog3,
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    description:
      "Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat",
  },
  {
    date: "April 4,2018",
    comments: 5,
    image: blog1,
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    description:
      "Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat",
  },
];
export const productData = [
  {
    id: 0,
    name: "Black Sweter",
    type: "towel",
    price: 23.4,
    oldPrice: 67.7,
    category: "sweter",
    image: sweter1,
  },
  {
    id: 1,
    name: "Warm Check Sweter",
    type: "cardigans",
    price: 13.9,
    sale: true,
    category: "sweter",
    image: sweter2,
  },
  {
    id: 2,
    name: "Tunics Ladies Sweter",
    type: "tunics",
    price: 43.4,
    oldPrice: 67.7,
    category: "sweter",
    image: sweter3,
  },
  {
    id: 3,
    name: "Turtlenecks Sweter",
    type: "turtlenecks",
    price: 39.5,
    category: "sweter",
    image: sweter4,
  },
  {
    id: 4,
    name: "Orange Huddy",
    type: "pullovers",
    price: 20.2,
    category: "sweter",
    image: sweter5,
    sale: true,
  },
  {
    id: 5,
    name: "Diomond Braclet",
    type: "braclet",
    price: 243,
    category: "accesories",
    image: accessories1,
    sale: true,
  },
  {
    id: 6,
    name: "Golden Eyelaner",
    type: "EyeLen",
    oldPrice: 189.4,
    price: 113.9,
    category: "accesories",
    image: accessories2,
  },
  {
    id: 7,
    name: "Smart Watch",
    type: "watch",
    price: 67.4,
    category: "accesories",
    image: accessories3,
  },
  {
    id: 8,
    name: "Golden Classic Watch",
    type: "watch",
    price: 339.5,
    category: "accesories",
    image: accessories4,
    sale: true,
  },
  {
    id: 9,
    name: "Designed Sport Shoe",
    type: "sport",
    oldPrice: 189.4,
    price: 89.3,
    category: "shoe",
    image: shoe1,
  },
  {
    id: 10,
    name: "Walking Shoe",
    type: "walking",
    price: 64.3,
    category: "shoe",
    sale: true,
    image: shoe2,
  },
  {
    id: 11,
    name: "Comfortable Shoe",
    type: "sport",
    price: 99.3,
    category: "shoe",
    image: shoe3,
  },
  {
    id: 12,
    name: "Pullovers Shoe",
    type: "pullovers",
    price: 20.2,
    oldPrice: 189.4,
    category: "shoe",
    sale: true,
    image: shoe4,
  },
  {
    id: 13,
    name: "Gray Shoe",
    type: "pullovers",
    price: 45.2,
    category: "shoe",
    image: shoe5,
  },
  {
    id: 14,
    name: "Leather Handbag",
    type: "cordigans",
    price: 145.2,
    category: "handbag",
    image: handbag1,
  },
  {
    id: 15,
    name: "Whiteshape Handbag",
    type: "whiteshape",
    price: 236.2,
    category: "handbag",
    image: handbag2,
  },
  {
    id: 16,
    name: "Touring Handbag",
    type: "tour",
    price: 315.9,
    oldPrice: 189.4,
    category: "handbag",
    sale: true,
    image: handbag3,
  },
  {
    id: 17,
    name: "Stylish Handbag",
    type: "stylish",
    price: 545.2,
    category: "handbag",
    image: handbag4,
    sale: true,
  },
  {
    id: 18,
    name: "Leather mini Handbag",
    type: "leather",
    price: 295.2,
    category: "handbag",
    image: handbag5,
    oldPrice: 189.4,
  },
];

import searchIcon from "../../assets/images/searchIcon.jpg";
import "./SearchBox.styles.scss";
const SearchBox = () => {
  return (
    <div class="search-form">
      <h4>Search</h4>
      <form action="#">
        <input type="text" placeholder="Search . . ."></input>
        <button type="submit">
          <img src={searchIcon} />
        </button>
      </form>
    </div>
  );
};
export default SearchBox;

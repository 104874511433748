import React from 'react';
import {useState,useLayoutEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Autoplay } from "swiper";

import 'swiper/css';
import 'swiper/css/bundle';

import './FooterCarousel.styles.scss'
import logo1 from '../../assets/images/carousel-logo-1.webp'
import logo2 from '../../assets/images/carousel-logo-2.webp'
import logo3 from '../../assets/images/carousel-logo-3.webp'
import logo4 from '../../assets/images/carousel-logo-4.webp'
import logo5 from '../../assets/images/carousel-logo-5.webp'

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function FooterCarousel(){
  SwiperCore.use([Autoplay]);
  const [width, height] = useWindowSize();

    return (
      <div className="Footer_Carousel_Container">
       <Swiper
       className="carousel_swiper"
      spaceBetween={20}
      slidesPerView={width < 1000 ? 3 : 5}
      modules={Autoplay}
      autoplay={width < 1000 ? {delay:3000} : {delay:99999}}
      loop={true}
    >
      <SwiperSlide><img src={logo1} /></SwiperSlide>
      <SwiperSlide><img src={logo2} /></SwiperSlide>
      <SwiperSlide><img src={logo3} /></SwiperSlide>
      <SwiperSlide><img src={logo4} /></SwiperSlide>
      <SwiperSlide><img src={logo5} /></SwiperSlide>

    </Swiper>
    </div>
    );
  
}
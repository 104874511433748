import React,{ useState } from "react";
import { faqs } from "./data"

export default function FaqCard() {
  const [selected, setSelected] = useState(null)
  const toggle = (i) =>{
    if(selected === i){
        return setSelected(null)
    }
    setSelected(i)
  }

  return(
    <div className="accordion">
      {faqs.map((item ,i) =>
        <div className="item">
          <div className="title " onClick={() => toggle(i)}>
           
            <p> <div className={`plus-minus ${selected===i ? "orange":""}`}>{selected === i ? '-' : '+' }</div>{item.title}</p>
          </div>
          <div className={selected === i ? 'content show' : 'content' }>
              {item.description}
          </div>
        </div>
        )}
      </div>
  )
}


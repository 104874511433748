import React from 'react';
import locationIcon from '../../assets/svgs/location.svg'

import './ContactMap.styles.scss'



const ContactMap = () =>{
 

    return (
      <div className="map_container" >
        <img src={locationIcon}/>
        <iframe className="contact_map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48158.305462977965!2d-74.13283844036356!3d41.02757295168286!2m 3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2e440473470d7%3A0xcaf503ca2ee57958!2sSaddle%20River%2C%20NJ%2007458%2C%20USA!5e0!3m2!1sen!2sbd!4v1575917275626!5m2!1sen!2sbd"  style={{border:'0'}} allowFullScreen="">
        </iframe> 
        
      </div>
    );
  
}

export default ContactMap;
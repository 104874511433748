import './Categories.styles.scss'
const Categories = () => {
  return(
    <div className="blog-category">
      <h4>Categories</h4>
      <ul>
        <li><a href="#">Fashion</a></li>
        <li><a href="#">Travel</a></li>
        <li><a href="#">Picnic</a></li>
        <li><a href="#">Model</a></li>

      </ul>
    </div>
  );
}
export default  Categories;
import React from 'react';
import ContactMap from '../../components/Contact/ContactMap'
import ContactForm from '../../components/Contact/ContactForm'

import './Contact.styles.scss'






const Contact = () =>{
 

    return (
      <div >
       <ContactMap/>
       <ContactForm/>
      </div>
    );
  
}

export default Contact;
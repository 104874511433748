import FooterCarousel from '../../components/Footer/FooterCarousel';
import FooterContent from '../../components/Footer/FooterContent';

const Footer = () =>{
    return(
        <div>
         <FooterCarousel/> 
        <FooterContent/>
        </div>
    )
}
export default Footer

import React from "react";
import "./CommentSection.styles.scss"
import image from "../../assets/images/instagram/2.webp";

export default function CommentSection() {
    return(
        <div className="posted_by">
            <div className="posted_by_pic">
                <img src={image} className="posted_by_img"></img>
            </div>
            <div className="posted_by_text">
                <a href="#"><h5>Lorem ipsum</h5></a>
                <p className="posted_par">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ultrices mattis neque, sit amet feugiat tortor varius consectetur.
                 Morbi efficitur risus lacus, eleifend viverra tortor posuere ac.
                </p>
            </div>
        </div>
    )
}
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlist: [
    {
      id: 0,
      name: "braclet diomond",
      type: "braclet",
      price: 243,
      category: "accesories",
      image: "/static/media/accesories-1.89fad6ec68ef9068f420.webp",
      sale: true,
    },
    {
      id: 1,
      name: "tunics sweeter",
      type: "tunics",
      price: 43.4,
      oldPrice: 67.7,
      category: "sweter",
      image: "/static/media/sweter-1.45be073054c3d96dd9cc.webp",
    },
    {
      id: 2,
      name: "gangjau sweeter",
      type: "towel",
      price: 23.4,
      oldPrice: 67.7,
      category: "sweter",
      image: "/static/media/sweter-3.bf0614ba564ee1c72e91.webp",
    },
  ],
};

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    ADD_WISHLIST_ITEM: (state, action) => {
      state.wishlist.push(action.payload);
    },
    REMOVE_WISHLIST_ITEM: (state, action) => {
      state.wishlist.splice(action.payload, 1);
    },
    CLEAR_WISHLIST: (state, action) => {
      state.wishlist = [];
    },
  },
});

export const {
  ADD_WISHLIST_ITEM,
  REMOVE_WISHLIST_ITEM,
  CLEAR_WISHLIST,
  GET_TOTAL_COUNT,
} = wishlistSlice.actions;

export default wishlistSlice.reducer;

import React from "react";
import "./styles.scss";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import { data } from "./data";
import HeaderCard from "../../components/HeaderCard/HeaderCard";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
export default function Header() {
  return (
    <>
      <Swiper
        modules={[Navigation, EffectFade]}
        effect={"fade"}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        {data.map((el) => (
          <SwiperSlide>
            <HeaderCard
              img={el.image}
              title={el.title}
              desc={el.desc}
              category={el.category}
              desc2={el.dec2}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

import React, { useEffect, useState } from "react";
import "./HomepageCarousel.scss";
import carouselBannerWomen from "../../assets/images/carousel-banner-women.webp";
import carouselBannerMan from "../../assets/images/carousel-banner-man.webp";

import { productData } from "../../assets/data";
import CarouselSlider from "../../components/CarouselSlider/CarouselSlider";
import AOS from "aos";
import "aos/dist/aos.css";

const HomepageCarousel = ({ reverse }) => {
  const tabs = ["sweter", "accesories", "shoe", "handbag"];
  const [selected, setSelected] = useState(tabs[0]);
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    AOS.init({ once: true });
    AOS.refresh();
  }, []);

  useEffect(() => {
    const filteredData = productData.filter(
      (item) => item.category === selected
    );
    setSelectedData(filteredData);
  }, [selected, reverse]);

  return (
    <div
      className={`Homepage-Carousel-Section ${reverse && "Carousel-Reverse"}`}
    >
      <div
        data-aos="fade-right"
        data-aos-duration="2000"
        className="Homepage-Carousel-Banner"
        style={{
          background: `url(${
            reverse ? carouselBannerMan : carouselBannerWomen
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-reapet",
        }}
      >
        <div className="Homepage-Carousel-Content">
          <h2>{reverse ? "Men's" : "Women's"}</h2>
          <p>Discover more</p>
        </div>
        <div className="Homepage-Banner-Carousel-Banner-Overlay"></div>
      </div>
      <div
        data-aos="fade-left"
        data-aos-duration="2000"
        className="Homepage-Carousel-Container"
      >
        <div className="Homepage-Carousel-Tab">
          <ul className="Homepage-Carousel-Tab-Container">
            {tabs.map((tab, index) => (
              <li
                key={index}
                onClick={() => setSelected(tab)}
                className={`Homepage-Carousel-Tab-Button ${
                  selected === tab && "Tab-Active"
                }`}
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="Homepage-Carousel-Slider-Container">
          <CarouselSlider data={selectedData} />
        </div>
      </div>
    </div>
  );
};

export default HomepageCarousel;

import React from "react";
import "./styles.scss";
import ServiceCard from "../../components/ServiceCard/Service";
import { data } from "./data";
export default function Services() {
  return (
    <div className="services__container">
      {data.map((service) => (
        <ServiceCard
          img={service.img}
          title={service.title}
          description={service.description}
        />
      ))}
    </div>
  );
}

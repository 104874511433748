import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REMOVE_WISHLIST_ITEM } from "../../redux/wishlist";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import "./NavbarHoverWishlist.styles.scss";

const NavbarHoverWishlist = () => {
  const { wishlist } = useSelector((state) => state.wishlist);

  const dispatch = useDispatch();

  return (
    <div className="Navbar-Hover-Wishlist">
      <ul className="Navbar-Hover-Wishlist-Container">
        {wishlist?.map((item) => (
          <li className="Navbar-Hover-Wishlist-Item">
            <div className="Navbar-Hover-Wishlist-Item-Left">
              <img
                src={item?.image}
                className="Navbar-Hover-Wishlist-Item-Image"
                alt=""
              />
              <p className="Navbar-Hover-Wishlist-Item-Text">
                <span className="Navbar-Hover-Wishlist-Item-Name">
                  {item?.name}
                </span>
                <span className="Navbar-Hover-Wishlist-Item-Price">
                  {item?.oldPrice && (
                    <span className="Item-OldPrice">$ {item?.oldPrice} </span>
                  )}
                  $ {item?.price}
                </span>
              </p>
            </div>
            <div
              onClick={() =>
                dispatch(REMOVE_WISHLIST_ITEM(wishlist.indexOf(item)))
              }
              className="Navbar-Hover-Wishlist-Item-Right"
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </li>
        ))}
      </ul>
      <div className="Navbar-Hover-Wishlist-Bottom">
        {/* <p className="Navbar-Hover-Wishlist-Total">
          <span>total:</span>
          <span>
            $
            {cartPrices.length > 0
              ? cartPrices.reduce((prev, next) => prev + next).toFixed(2)
              : 0}
          </span>
        </p> */}
        <Link
          to="wishlist"
          className="Navbar-Hover-Wishlist-Button View-Button"
        >
          view Wishlist
        </Link>
        {/* <Link to="cart" className="Navbar-Hover-Wishlist-Button Cart-Button">
          cart
        </Link> */}
      </div>
    </div>
  );
};

export default NavbarHoverWishlist;

import React from "react";
import { Outlet } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";

const SharedBreadCrumb = () => {
  return (
    <div>
      <BreadCrumb />
      <Outlet />
      
    </div>
  );
};

export default SharedBreadCrumb;

import React from "react";
import Clock from "./Clock";
import "./styles.scss";
export default function Deals() {
  let deadline = "october, 1, 2022";
  return (
    <div className="deals__container">
      <div className="deals__details">
        <div className="deals__title">
          <span className="deals__title">Deals Of The Week</span>
        </div>
        <div className="deals__description">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do ipsum
          dolor sit amet, consectetur adipisicing elit
        </div>
        <div className="deals__price">
          <span>$35.00</span> / HanBag
        </div>
        <Clock deadline={deadline} />
        <button className="deals__button">shop now</button>
      </div>
    </div>
  );
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faPinterest,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import usaIcon from "../../assets/svgs/usa-flag.svg";
import "./NavbarTop.styles.scss";

const NavbarTop = () => {
  return (
    <div className="Navbar-Top-Container">
      <div className="Navbar-Top-Left">
        <p className="Navbar-Contact-Icon">
          <FontAwesomeIcon
            className="Navbar-Contact-Icon-Icon"
            icon={faEnvelope}
          />
          <span className="Navbar-Contact-Icon-Text">
            hello.reboost@gmail.com
          </span>
        </p>
        <div className="Navbar-Devider"></div>
        <p className="Navbar-Contact-Icon">
          <FontAwesomeIcon
            className="Navbar-Contact-Icon-Icon"
            icon={faPhone}
          />
          <span className="Navbar-Contact-Icon-Text">+(123) 456789</span>
        </p>
      </div>
      <div className="Navbar-Top-Right">
        <p className="Navbar-Social-Icons">
          <FontAwesomeIcon icon={faFacebookF} />
          <FontAwesomeIcon icon={faTwitter} />
          <FontAwesomeIcon icon={faLinkedin} />
          <FontAwesomeIcon icon={faPinterest} />
        </p>
        <div className="Navbar-Devider"></div>
        <p className="Navbar-Language-Change">
          <img className="Navbar-Language-Change-Text" src={usaIcon} alt="" />
          <span className="Navbar-Language-Change-Image">English</span>
        </p>
        <div className="Navbar-Devider"></div>
        <p className="Navbar-Login">
          <FontAwesomeIcon className="Navbar-Login-Icon" icon={faUser} />
          <span className="Navbar-Login-Text">Login</span>
        </p>
      </div>
    </div>
  );
};

export default NavbarTop;

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/free-mode";
import "swiper/scss/pagination";
import "swiper/css/navigation";
import "./CarouselSlider.scss";
import "./style.css";

import ProductItem from "../ProductItem/ProductItem";

export default function CarouselSlider({ data }) {
  return (
    <>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          668: {
            slidesPerView: 2,
          },
          1280: {
            slidesPerView: 3,
          },
        }}
        pagination={{
          clickable: true,
          bulletActiveClass: "current-active-bullet",
        }}
        autoplay={{
          delay: 4000,
        }}
        speed={700}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {data?.map((item, index) => (
          <SwiperSlide
            style={{ width: "100%" }}
            className="Carousel-Container"
            key={index}
          >
            <ProductItem item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

import React from "react";
import "./Navbar.styles.scss";
import NavbarBottom from "./NavbarBottom";
import NavbarMiddle from "./NavbarMiddle";

import NavbarTop from "./NavbarTop";

const Navbar = () => {
  return (
    <nav className="Navbar-Main">
      <div className="Navbar-Top">
        <NavbarTop />
      </div>
      <div className="Navbar-Middle">
        <NavbarMiddle />
      </div>
      <div className="Navbar-Bottom">
        <NavbarBottom />
      </div>
    </nav>
  );
};

export default Navbar;

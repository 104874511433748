export const faqs = [
    {
      key: 1,
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      title: "Lorem ipsum Lorem ipsum ipsum",
    },
    {
      key: 2,
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
      title: "Lorem ipsum",
    },
    {
      key: 3,
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor ",
      title: "Lorem ipsum ",
    },
  ];
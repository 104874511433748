import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cart: [
    {
      id: 2,
      name: "tunics sweeter",
      type: "tunics",
      price: 43.4,
      oldPrice: 67.7,
      category: "sweter",
      image: "/static/media/sweter-3.bf0614ba564ee1c72e91.webp",
      quantity: 1,
    },
    {
      id: 0,
      name: "gangjau sweeter",
      type: "towel",
      price: 23.4,
      oldPrice: 67.7,
      category: "sweter",
      image: "/static/media/sweter-1.45be073054c3d96dd9cc.webp",
      quantity: 1,
    },
    {
      id: 5,
      name: "braclet diomond",
      type: "braclet",
      price: 243,
      category: "accesories",
      image: "/static/media/accesories-1.89fad6ec68ef9068f420.webp",
      sale: true,
      quantity: 1,
    },
  ],
  total: 0,
  totalquantity: 0,
};
export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ADD_ITEM: (state, action) => {
      state.cart.push({ ...action.payload, quantity: 1 });
    },
    REMOVE_ITEM_BY_INDEX: (state, action) => {
      state.cart.splice(action.payload, 1);
    },
    CLEAR_CART: (state) => {
      state.cart = [];
    },
    ADD_QUANTITY_BY_1: (state, action) => {
      state.cart[action.payload].quantity += 1;
    },
    DEDUCE_QUANTITY_BY_1: (state, action) => {
      state.cart[action.payload].quantity -= 1;
    },
    GET_TOTAL_QUANTITY: (state) => {
      let quantity = state.cart.map((item) => 1 * item.quantity);
      state.totalquantity =
        state.cart.length > 0
          ? quantity.reduce((prev, next) => prev + next)
          : 0;
    },
    GET_TOTAL: (state) => {
      let cartPrices = state.cart.map(
        (item, quantity) => item.price * item.quantity
      );
      state.total =
        state.cart.length > 0
          ? cartPrices.reduce((prev, next) => prev + next).toFixed(2)
          : 0;
    },
  },
});
export const {
  ADD_ITEM,
  REMOVE_ITEM_BY_INDEX,
  CLEAR_CART,
  ADD_QUANTITY_BY_1,
  DEDUCE_QUANTITY_BY_1,
  GET_TOTAL_QUANTITY,
  GET_TOTAL,
} = cartSlice.actions;
export default cartSlice.reducer;

import React from "react";
import "./HomePage.scss";
import Header from "../../sections/Header/Header";

import BlogSection from "../../sections/Blog/BlogSection";
import InstagramPosts from "../../sections/InstagramPosts/InstagramPosts";
import Categories from "../../sections/categories/Categories";
import Services from "../../sections/Services/Services";
import HomepageCarousel from "../../sections/HomepageCarousel/HomepageCarousel.jsx";
import Deals from "../../sections/Deals/Deals";
import MenOutlet from "../../sections/HomepageCarouselMen/HomepageCarouselMen"

const HomePage = () => {
  return (
    <div className="HomePage_Container">
      <Header />
      <Categories />
      <HomepageCarousel />
      <Deals />
      <MenOutlet/>
      <InstagramPosts />
      <BlogSection />
      <Services />
    </div>
  );
};

export default HomePage;

import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./NavbarBottom.styles.scss";
import menuItems from "./menu";

const NavbarBottom = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(1);

  return (
    <div className="Navbar-Bottom-Container">
      <div className="Navbar-Bottom-Top-Menu">
        <div className="Navbar-Bottom-Top-Menu-Left">
          <p className="Navbar-Bottom-Top-Menu-Category">
            <FontAwesomeIcon icon={faBars} />
            <span>All Departments</span>
            <div className="Navbar-Category-Lists">
              <ul className="Navbar-Category-List-Container">
                <li className="Navbar-Category-List-Item">Men's Clothing</li>
                <li className="Navbar-Category-List-Item">Women's Clothing</li>
                <li className="Navbar-Category-List-Item">T - Shirt</li>
                <li className="Navbar-Category-List-Item">Luxury</li>
                <li className="Navbar-Category-List-Item">Branding</li>
              </ul>
            </div>
          </p>
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
        <div className="Navbar-Bottom-Top-Menu-Right">
          <ul className="Navbar-Bottom-Main-Menu">
            {/* <li>
              <Link className="Navbar-Bottom-Menu-Item Active" to="">
                Home
              </Link>
            </li> */}
            {menuItems.map((item) => {
              if (!item.dropdown) {
                return (
                  <li key={item.id}>
                    <Link
                      onClick={() => setActiveMenu(item.id)}
                      className={`Navbar-Bottom-Menu-Item ${
                        activeMenu === item.id ? "Active" : ""
                      }`}
                      to={item.link}
                    >
                      {item.text}
                    </Link>
                  </li>
                );
              }
              return (
                <li
                  onClick={() => setActiveMenu(item.id)}
                  className={`Navbar-Bottom-Submenu-Container ${
                    activeMenu === item.id ? "Active" : ""
                  } `}
                  key={item.id}
                >
                  {item.text}
                  {item.dropdown && (
                    <ul className="Navbar-Bottom-Menu-Submenu">
                      {item.subItem.map((subItem) => (
                        <li
                          className="Navbar-Bottom-Menu-Submenu-Item"
                          key={subItem.id}
                        >
                          <Link to={subItem.link}>{subItem.text}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div
        className="Navbar-Bottom-Bottom-Menu"
        style={{
          height: `${openMenu ? "350px" : "50px"}`,
          transition: "0.5s",
        }}
      >
        <p
          onClick={() => setOpenMenu(!openMenu)}
          className="Navbar-Bottom-Bottom-Menu-Button"
        >
          <FontAwesomeIcon icon={faBars} />
          <span>Menu</span>
        </p>
        <ul
          className="Navbar-Bottom-Bottom-Mobile-Menu"
          style={{
            opacity: `${openMenu ? "1" : "0"}`,
            zIndex: `${openMenu ? "2" : "-2"}`,
            transition: "0.4s",
          }}
        >
          <li>
            <Link className="Navbar-Bottom-Bottom-Mobile-Menu-Item" to="/">
              Home
            </Link>
          </li>
          {menuItems.map((item) => {
            if (!item.dropdown) {
              return (
                <li key={item.id}>
                  <Link
                    className="Navbar-Bottom-Bottom-Mobile-Menu-Item"
                    to={item?.link}
                  >
                    {item.text}
                  </Link>
                </li>
              );
            }
            return (
              <li
                className="Navbar-Bottom-Mobile-Submenu-Container"
                key={item.id}
              >
                {item.text}
                {/* {item.dropdown && (
                  <ul className="Navbar-Bottom-Mobile-Submenu">
                    {item.subItem.map((subItem) => (
                      <li
                        className="Navbar-Bottom-Mobile-Submenu-Item"
                        key={subItem.id}
                      >
                        <Link to={subItem.link}>{subItem.text}</Link>
                      </li>
                    ))}
                  </ul>
                )} */}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default NavbarBottom;

import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import pin from '../../assets/svgs/pin.svg'
import phone from '../../assets/svgs/phone.svg'
import mail from '../../assets/svgs/mail.svg'

import './ContactForm.styles.scss';



const Column1 = ({title,description}) => {

  const contactInfo =[
    {
      spanText:'Address:',
      pText:'60-49 Road 11378 New York',
      imgSrc:`${pin}`
    },
    {
      spanText:'Phone:',
      pText:'+65 11.188.888',
      imgSrc:`${phone}`
    },
    {
      spanText:'Email:',
      pText:'hellocolorlib@gmail.com',
      imgSrc:`${mail}`
    }
  ];

    return(
        <div className='Contact_Column'>
            <h1>{title}</h1>
            <p>{description}</p>
              {contactInfo.map ( (info) => 
              
                <div className='Form_Card'>  
                  <img src={info.imgSrc}/>
                  <div className='Form_Card_Text'>
                  <span>{info.spanText}</span>
                  <p>{info.pText}</p>
                  </div>
                  </div>
              
              )
              }
           
        </div>
    )
}


const InfoSection = () => {
    return (
        <div className='Contact_Info_Container' >
            <Column1 title="Contact Us"  description="  Contrary to popular belief, Lorem Ipsum is simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              maki years old."/>
            <div className='FormContactInfo'>
                <h1>Leave A Comment</h1>
                <p>
                Our staff will call back later and answer your questions.
                </p>
                <form action="#" className="contact_form">
                  <div className='form_name_mail'>
                  <input type="text" placeholder="Your name" className="contact_form_col" /> 
                  
                  <input  type="email" placeholder="Your email" className="contact_form_col" id="form-mail"/>
                  </div>
                  <textarea type="text" placeholder="Your message"/> 
                  <button type="button"> Send Message</button>
                </form>
            </div>
         
        </div>
    )
}
const FooterContent = () => {
    return (
        
        <div className='Contact_Container' >
            <InfoSection />
          
        </div>
    )
}


export default FooterContent

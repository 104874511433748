import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
export default function HeaderCard({ img, category, desc, desc2, title }) {
  return (
    <div
      className="header__container"
      style={{ backgroundImage: `url('${img}')` }}
    >
      <Fade bottom cascade>
        <div className="header__info">
          <p className="header__category">{category}</p>
          <p className="header__title">{title}</p>
          <p className="header__desc">
            {desc} <br /> {desc2}
          </p>
          <button className="header__btn">
            <Link to={"/shop"}>SHOP NOW</Link>
          </button>
        </div>
      </Fade>
      <div className="circle">
        <div className="inner__cirlce">
          SALE <br /> 50%
        </div>
      </div>
    </div>
  );
}

import React from "react";

import { Login } from "../../sections/Register/Login";
function LoginPage() {
  return (
    <>
      <Login />
    </>
  );
}

export default LoginPage;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GET_TOTAL } from "../../redux/cart";
export default function Order() {

    const { cart } = useSelector((state) => state.cart);
    const { total } = useSelector((state) => state.cart);
    let dispatch = useDispatch();
    const [getTotal, setTotal] = useState(0);

    useEffect(() => {
        dispatch(GET_TOTAL());
        setTotal(total);
      }, [cart,total]);

    return(
        <div className="checkout_order">
            <div className="checkout_content">
                <input type="text" placeholder="Enter Your Coupon Code"></input>
            </div>
            <div className="place_order">
                <h4>Your Order</h4>
                <div className="order_total">
                    <ul className="order_table">
                        <li>
                            Product <span>Total</span>
                        </li>
                        {cart.map((item) => (
                            <li className="fw_normal">
                                {item.name} x {item.quantity} <span>${item.price * item.quantity}</span>
                            </li>
                        ))}
                        <li className="fw_normal">
                            Subtotal <span>${getTotal}</span>
                        </li>
                        <li className="total_price">
                            Total <span>${getTotal}</span>
                        </li>
                       
                    </ul>
                    <div className="payment_check">
                        <div>
                            <label>Cheque Payment
                                <input type="checkbox" className="checkmark"></input>
                            </label>
                        </div>
                        <div>
                            <label>Paypal
                                <input type="checkbox" className="checkmark"></input>
                            </label>
                        </div>
                    </div>
                    <div className="order_btn">
                        <button type="submit" className="site_btn place_btn">
                            Place Order
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
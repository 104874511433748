import React from "react";
import { useLocation } from "react-router-dom";
import { AiTwotoneHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./BreadCrumb.styles.scss"

export default function BreadCrumb () {
  const location = useLocation()
  return(
      <div className="breadcrumb_text">
        <p className="home__link"><AiTwotoneHome/><Link to={"/"} >Home</Link>  <span className="greater_than">&gt; </span> <span className="breadcrumb_main">{location.pathname.split("/")[1]}</span></p>
      </div>
  )
}
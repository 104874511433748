const menuItems = [
  {
    id: 1,
    link: "",
    text: "Home",
  },
  {
    id: 2,
    link: "shop",
    text: "Shop",
  },
  //   {
  //     id: 2,
  //     text: "Collection",
  //     dropdown: true,
  //     subItem: [
  //       {
  //         id: 1,
  //         text: "Men's",
  //         link: "men",
  //       },
  //       {
  //         id: 2,
  //         text: "Women's",
  //         link: "women",
  //       },
  //       {
  //         id: 3,
  //         text: "Kid's",
  //         link: "kid",
  //       },
  //     ],
  //   },
  {
    id: 3,
    link: "blog",
    text: "Blog",
  },
  {
    id: 4,
    link: "contact",
    text: "Contact",
  },
  {
    id: 5,
    text: "Pages",
    dropdown: true,
    subItem: [
      {
        id: 1,
        text: "Blog Details",
        link: "blogdetail",
      },
      {
        id: 2,
        text: "Shopping Cart",
        link: "cart",
      },
      {
        id: 3,
        text: "Checkout",
        link: "checkout",
      },
      {
        id: 4,
        text: "Faq",
        link: "faq",
      },
      {
        id: 5,
        text: "Register",
        link: "register",
      },
      {
        id: 6,
        text: "Login",
        link: "login",
      },
    ],
  },
];

export default menuItems;

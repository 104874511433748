export default function ServiceCard({ img, title, description }) {
  return (
    <div className="service">
      <img src={img} alt="" width={"78px"} />
      <div className="services__info">
        <span>{title}</span>
        <span>{description}</span>
      </div>
    </div>
  );
}

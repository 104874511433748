import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ShopItems } from "../../sections/ShopItems/ShopItems";
import Panel from "../../sections/ShopPanel/Panel";
import "./ShopPage.styles.scss";


import { ADD_ITEM, REMOVE_ITEM_BY_INDEX, CLEAR_CART } from "../../redux/cart";
function ShopPage() {

  return (
      <div className="shop__container">
        <Panel/>
        <ShopItems />
      </div>
  );
}

export default ShopPage;

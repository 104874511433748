import React from "react";
import "./BlogDetail.styles.scss"
import image1 from "../../assets/images/blog2.webp"
import image2 from "../../assets/images/blogMore.webp"
import image from "../../assets/images/instagram/2.webp";
import { AiFillTags } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faTwitter,
    faYoutube,
    faInstagram,
    faGoogle,
  } from "@fortawesome/free-brands-svg-icons";

export default function BlogDetail() {
    return(
        <div className="blog_detail_part_margin">
            <div className="blog_detail_title">
                <h2>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</h2>
                <p className="blog_detail_par">
                    Lorem<span>- May 19, 2019</span>
                </p>
            </div>
            <div className="blog_large_pic">
                <img src={image1} className="blog_img"></img>
            </div>
            <div className="blog_detail_description">
                <p className="blog_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ultrices mattis neque, sit amet feugiat tortor varius consectetur.
                 Morbi efficitur risus lacus, eleifend viverra tortor posuere ac. Nullam maximus nisi eget erat fringilla sagittis.
                  Etiam mattis semper erat, a fermentum quam porta finibus. Cras et nibh mauris. 
                Integer nec diam a lorem convallis placerat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Nullam eget pellentesque eros. 
                </p>
            </div>
            <div className="blog_quote">
                <p>“ Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ultrices mattis neque, sit amet feugiat tortor varius consectetur. Eleifend viverra tortor posuere ac. ” 
                <span>- Steven Jobs</span>
                </p>
            </div>
            <div className="blog_more_img">
                <div className="blog_row">
                    <div className="col-sm-4">
                        <img className="more_img" src={image2}></img>
                    </div>
                    <div className="col-sm-4">
                        <img className="more_img" src={image2}></img>
                    </div>
                    <div className="col-sm-4">
                        <img className="more_img" src={image2}></img>
                    </div>
                </div>
            </div>
            <p className="blog_img_desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Curabitur ultrices mattis neque, sit amet feugiat tortor varius consectetur. 
            Morbi efficitur risus lacus, eleifend viverra tortor posuere ac.
             Nullam maximus nisi eget erat fringilla sagittis. Etiam mattis semper erat, a fermentum quam porta finibus. 
            </p>
            <div className="tag_share">
                <div className="tage_detail">
                    <ul>
                        <li>
                            <AiFillTags className="tag_icon"/>
                        </li>
                        <li>Travel</li>
                        <li>Beauty</li>
                        <li>Fashion</li>
                    </ul>
                </div>
                <div className="blog_share">
                    <span>Share:</span>
                    <div className="social_links">
                        <p className="blog_social_icons">
                            <FontAwesomeIcon icon={faFacebookF} />
                            <FontAwesomeIcon icon={faTwitter} />
                            <FontAwesomeIcon icon={faGoogle} />
                            <FontAwesomeIcon icon={faInstagram} />
                            <FontAwesomeIcon icon={faYoutube} />
                        </p>
                    </div>
                </div>
            </div>
                <div className="blog_prev_next">
                    <div className="row">
                        <div className="prev__blog">
                            <a className="blog_prev_row">
                                <div className="prev_blog">
                                    <AiOutlineArrowLeft className="left_arrow"/>
                                    <img className="prev_blog_img" src={image}></img>
                                </div>
                                <div className="par_prev_text">
                                    <span>Previous Post:</span>
                                    <h5>The Personality Trait That Makes People Happier</h5>
                                </div>
                            </a>
                        </div>
                        <div className="next__blog">
                            <a className="blog_next_row">
                                <div className="next_blog">
                                    <img className="next_blog_img" src={image}></img>
                                    <AiOutlineArrowRight className="right_arrow"/>
                                </div>
                                <div className="par_next_text">
                                    <span>Next Post:</span>
                                    <h5>The Personality Trait That Makes People Happier</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
        </div>
    )
}
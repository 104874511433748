import React from "react";
import "./CommentForm.styles.scss"

export default function FaqCard() {
    return(
        <div className="leave_comment">
            <h4>Leave A Comment</h4>
            <form className="comment_form">
                <div className="user_name">
                    <div className="first_last_name">
                        <input type="text" placeholder="Name"></input>
                    </div>
                    <div className="first_last_name" id="last_name">
                        <input type="text" placeholder="Email"></input>
                    </div>
                </div>
                <textarea type="text" placeholder="Messages"></textarea>
                <button type="submit" className="site_btn">Send message</button>
            </form>
        </div>
    )
}
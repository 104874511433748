import Men from "../../assets/images/men.webp";
import Women from "../../assets/images/women.webp";
import Kid from "../../assets/images/kid.webp";

export const data = [
  {
    category: "Men's",
    image: Men,
  },
  {
    category: "Women's",
    image: Women,
  },
  {
    category: "Kid's",
    image: Kid,
  },
];

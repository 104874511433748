import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GET_TOTAL } from "../../redux/cart";
export default function CartPrice() {
  const { cart } = useSelector((state) => state.cart);
  const { total } = useSelector((state) => state.cart);
  let dispatch = useDispatch();
  const [getTotal, setTotal] = useState(0);

  useEffect(() => {
    dispatch(GET_TOTAL());
    setTotal(total);
  }, [cart,total]);
  
  return (
    <div className="price_container">
      <div className="cart_btn_discount">
        <div className="cart_buttons">
          <a href="#" className="primary_btn continue_shop">
            Continue shopping
          </a>
          <a href="#" className="primary_btn update_cart">
            Update cart
          </a>
        </div>
        <div className="discount">
          <h6>Discount Codes</h6>
          <form className="discount_form">
            <input type="text" placeholder="Enter your codes"></input>
            <button type="submit" className="site_btn discount_btn">
              Apply
            </button>
          </form>
        </div>
      </div>
      <div className="proceed_checkout">
        <ul>
          <li className="subtotal">
            Subtotal
            <span>${getTotal}</span>
          </li>
          <li className="cart_total">
            Total
            <span>${getTotal}</span>
          </li>
        </ul>
        <a href="#" className="proceed_btn">
          Proceed to check out
        </a>
      </div>
    </div>
  );
}

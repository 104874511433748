import img1 from "./delivery.svg";
import img2 from "./clock.svg";
import img3 from "./payment.svg";

export const data = [
  {
    title: "FREE SHIPPING",
    description: "For all order over 99$",
    img: img1,
  },
  {
    title: "DELIVERY ON TIME",
    description: "If good have problems",
    img: img2,
  },
  {
    title: "SECURE PAYMENT",
    description: "100% secure payment",
    img: img3,
  },
];

import React from "react";
import "./FaqPage.styles.scss"
import FaqCard from "../../sections/FaqCard/FaqCard";

export default function FaqPage() {
  return (
      <div className="faq_container">
        <FaqCard/>
      </div>
  );
}



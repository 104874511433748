/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./InstagramPost.styles.scss";
import { FaInstagram } from "react-icons/fa";

function InstagramPost({ img, url, text }) {
  return (
    <div className="Instagram_Post">
      <img src={img} alt="instagram post" className="Instagram_Image" />
      <a className="Instagram_Icon" href={url}>
        <FaInstagram size="35" color="#fff" />
        <span className="Instagram_Text">{text}</span>
      </a>
    </div>
  );
}

export default InstagramPost;

import React from "react";
import "./CheckoutPage.styles.scss"
import Billing from "../../sections/Billing/Billing";
import Order from "../../sections/Order/Order";

export default function CheckoutPage() {
    return(
            <section className="checkout_section">
            <div className="container">
                <form className="checkout_form">
                    <div className="checkout_row">  
                            <Billing />
                            <Order />
                    </div>
                </form>
            </div>
        </section>
    )
}
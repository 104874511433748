import React from "react";
import { Routes, Route } from "react-router-dom";

import SharedLayout from "./pages/SharedLayout";
import SharedBreadCrumb from "./pages/SharedBreadCrumb";
import AllBlogPost from "./pages/AllBlogPost/AllBlogPost";
import NotFound from "./pages/NotFound/NotFound";
import Homepage from "./pages/HomePage/Homepage";
import ShopPage from "./pages/ShopPage/ShopPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import FaqPage from "./pages/FaqPage/FaqPage";
import CheckoutPage from "./pages/CheckoutPage/CheckOutPage";
import CartPage from "./pages/CartPage/CartPage";
import BlogDetailPage from "./pages/BlogDetailPage/BlogDetailPage"
import Contact from "./sections/Contact/Contact"
import { useSelector, useDispatch } from "react-redux";
import { ADD_ITEM, REMOVE_ITEM_BY_INDEX, CLEAR_CART } from "./redux/cart";

function App() {

  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  return (
    <div className="App">
      <Routes>
        <Route path="" element={<SharedLayout />}>
          <Route index element={<Homepage />} />
          <Route path="" element={<SharedBreadCrumb />} >
            <Route path="blog" element={<AllBlogPost />} />
            <Route path="shop" element={<ShopPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="checkout" element={<CheckoutPage />} />
            <Route path="cart" element={<CartPage />} />
            <Route path="blogdetail" element={<BlogDetailPage />} />
            <Route path="contact" element={<Contact />} />
          </Route>
          
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
export default App;

import React from 'react'

import './NotFound.scss'

function NotFound() {
  return (
    <div className='NotFound_Container'>
        <h1>Hmmmm...</h1>
        <p>It looks like one of our developers fell asleep</p>
    </div>
  )
}

export default NotFound
import './Tags.styles.scss'
const Tags = () => {
  return(
    <div className="blog-tags">
      <h4>Product Tags</h4>
      <div className="tag-items">
        <a href="#">Towel</a>
        <a href="#">Shoes</a>        
        <a href="#">Coat</a>
        <a href="#">Dresses</a>
        <a href="#">Trousers</a>
        <a href="#">Men's Hats</a>
        <a href="#">Backpack</a>
      </div>
    </div>
  );
}
export default Tags;
import React from "react";

export default function Billing() {
    return(
        <div className="checkout_biiling">
            <div className="checkout_content">
                <a href="#" className="content_btn">Click Here To Login</a>
            </div>
            <h4>Biiling Details</h4>
            <div className="user_name">
                <div className="first_last_name">
                    <label >
                        First Name <span>*</span>
                    </label>
                    <input type="text"></input>
                </div>
                <div className="first_last_name" id="last_name">
                    <label>
                        Last Name <span>*</span>
                    </label>
                    <input type="text"></input>
                </div>
            </div>
            <label>Company Name</label>
            <input type="text"></input>
            <label >
                Country <span>*</span>
            </label>
            <input type="text"></input>
            <label >
                Street Address <span>*</span>
            </label>
            <input type="text"></input>
            <input type="text"></input>
            <label>Postcode/ZIP (optional)</label>
            <input type="text"></input>
            <label >
                Town / City <span>*</span>
            </label>
            <input type="text"></input>
            <div className="user_name">
                <div className="first_last_name">
                    <label >
                        Email Address <span>*</span>
                    </label>
                    <input type="text"></input>
                </div>
                <div className="first_last_name" id="last_name">
                    <label>
                        Phone <span>*</span>
                    </label>
                    <input type="text"></input>
                </div>
            </div>
            <div className="create_item">
                <label>Create an account?
                <input type="checkbox" className="checkmark"></input>
                </label>
            </div>
        </div>
    )
}
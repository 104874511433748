import React from "react"
import "./Register.styles.scss"
import { Link } from "react-router-dom";

export function Login() {
    return(
        <div className="register-login-section">
            <div className="col-lg-6 offset-lg-3">
                <div className="login-form">
                    <h2>Login</h2>
                    <form>
                        <div className="group-input">
                            <label>
                                Username or email address *
                            </label>
                            <input type="text"></input>
                        </div>
                        <div className="group-input">
                            <label>
                                Password *
                            </label>
                            <input type="password"></input>
                        </div>
                        <div className="group-input gi-check">
                            <div className="gi-more">
                                <label>Save Password
                                    <input type="checkbox" className="checkmark"></input>
                                </label>
                                <a href="" className="forget-pass">Forget your Password</a>
                            </div>
                        </div>
                        <button type="submit" className="site-btn register-btn">REGISTER</button>
                    </form>
                    <div className="switch-login">
                        <Link to="/register" className="or-login">Or CREATE AN ACCOUNT</Link>
                    </div>
                </div>
            </div>
        </div>
    )

}



import React from "react";
import "./AllBlogPost.scss";
import Blog from "../../components/Blog/Blog";
import SearchBox from '../../components/Blog/SearchBox';
import Categories from '../../components/Blog/Categories';
import Tags from '../../components/Blog/Tags';

import { data } from "../../sections/Blog/data";
import RecentPosts from "../../sections/RecentPosts/RecentPosts"
export default function BlogPart() {
  return (
    <div className="blog_page_section">
      <div className="blogs__panel">
        <SearchBox/>
        <Categories />
        <RecentPosts/>
        <Tags/>
        
      </div>
      <div className="blogs__page__container">
        {data.map((blog) => (
          <>
            <Blog
              title={blog.title}
              image={blog.image}
              date={blog.date}
              comments={blog.comments}
            />
            <Blog
              title={blog.title}
              image={blog.image}
              date={blog.date}
              comments={blog.comments}
            />
          </>
        ))}
      </div>
    </div>
  );
}
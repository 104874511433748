import blog1 from "../../assets/images/blog1.webp";
import blog2 from "../../assets/images/blog2.webp";
import blog3 from "../../assets/images/blog3.webp";

export const data = [
  {
    date: "May 4,2019",
    comments: 5,
    image: blog1,
    title: "The Best Street Style From London Fashion Week",
    description:
      "Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat",
  },
  {
    date: "May 4,2019",
    comments: 5,
    image: blog2,
    title: "The Best Street Style From London Fashion Week",
    description:
      "Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat",
  },
  {
    date: "May 4,2019",
    comments: 5,
    image: blog3,
    title: "The Best Street Style From London Fashion Week",
    description:
      "Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat",
  },
];
